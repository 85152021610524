import React, { useState, useEffect, useRef } from 'react';
import './CampaignReport.css';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faLightbulb, faCheckCircle, faArrowLeft, faDownload, faChevronLeft, faChevronRight, faBars} from '@fortawesome/free-solid-svg-icons';
import mixpanel from 'mixpanel-browser';
import * as XLSX from 'xlsx';

mixpanel.init('1702428ef9acdb5aa84df81256d5fe13', { debug: false, track_pageview: false, persistence: 'localStorage' });


const CampaignReport = () => {
  const { campaignId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { accessToken, idToken, testLink, liveLink } = location.state || {};
  const [reportData, setReportData] = useState(null);
  const [questionAnalysisData, setQuestionAnalysisData] = useState(null);
  const [selectedThemes, setSelectedThemes] = useState({});
  const [loading, setLoading] = useState(true); // New loading state
  const [mode, setMode] = useState('test');
  const [error, setError] = useState(null);
  const [respondentCount, setRespondentCount] = useState(0);
  const [transcripts, setTranscripts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedTranscript, setSelectedTranscript] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true); 
  const sectionRefs = useRef({});
  const [activeSection, setActiveSection] = useState('research-summary');
  const [expandedCells, setExpandedCells] = useState({});

  useEffect(() => {
    mixpanel.track('ReportPageViewed');
  }, []);

  useEffect(() => {
    const fetchData = async (url, setter) => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(url, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'X-ID-Token': idToken,
          }
        });

        if (!response.ok) {
          if (response.status === 404) {
            throw new Error('No respondents have completed an interview yet, please check back again after some time.');
          }
          const errorText = await response.text();
          throw new Error(errorText || 'Something went wrong, we are on it.');
        }

        const data = await response.json();
        
        setter(data);
        if (url.includes('question-analysis')) {
          setRespondentCount(data.version);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message || 'Failed to load data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    
    const interviewId = mode === 'test' ? testLink : liveLink;
    console.log(interviewId);

    if (interviewId) {
      fetchData(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}/interviews/${interviewId}/insights`, data => setReportData(data.summary));
      fetchData(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}/interviews/${interviewId}/question-analysis`, data => setQuestionAnalysisData(data.question_analysis.question_analysis));
    } else {
      setLoading(false);
      setError('Hey there! It looks like you haven\'t generated any interview link yet. To get started, simply complete the \'Create Research\' steps, and once your interviews are live, you\'ll begin seeing insightful results here.');
    }
  }, [campaignId, accessToken, idToken, mode, testLink, liveLink]);


  useEffect(() => {
    const fetchTranscripts = async () => {
      setLoading(true);
      setError(null);
      try {
        const interviewId = mode === 'test' ? testLink : liveLink;
        if (interviewId) {
        const response = await fetch(`https://dashboard-api.echovane.com/api/interviews/${interviewId}/transcripts?page=${currentPage}&page_size=10`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'X-ID-Token': idToken,
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch transcripts');
        }

        const data = await response.json();
        console.log(data);
        setTranscripts(data.transcripts);
        setTotalPages(data.total_pages || 1); // Use total_pages from backend response

        // Set the first transcript as selected by default
        if (data.transcripts.length > 0 && !selectedTranscript) {
          setSelectedTranscript(data.transcripts[0]);
        }
      }
      else {
        setError('Hey there! It looks like you haven\'t generated any interview link yet. To get started, simply complete the \'Create Research\' steps, and once your interviews are live, you\'ll begin seeing insightful results here.');
      }
      } catch (error) {
        console.error('Error fetching transcripts:', error);
        setError('Failed to load transcripts. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (campaignId && accessToken && idToken) {
      fetchTranscripts();
    }
  }, [campaignId, accessToken, idToken, mode, currentPage, testLink, liveLink]);

  useEffect(() => {
    // Initialize refs for each section
    sectionRefs.current = {
      'research-summary': React.createRef(),
      'key-findings': React.createRef(),
      'recommendations': React.createRef(),
      'question-analysis': React.createRef(),
      'transcripts': React.createRef(),
    };
  }, []);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setSelectedTranscript(null); 
  };

  const handleTranscriptClick = (transcript) => {
    setSelectedTranscript(transcript);
  };

  const toggleCellExpansion = (rowIndex, cellIndex) => {
    setExpandedCells(prev => {
      const key = `${rowIndex}-${cellIndex}`;
      return {
        ...prev,
        [key]: !prev[key]
      };
    });
  };

  const scrollToSection = (sectionId) => {
    const sectionRef = sectionRefs.current[sectionId];
    if (sectionRef && sectionRef.current) {
      const headerHeight = 100; 
      const extraPadding = 20; 
      const yOffset = headerHeight + extraPadding;
      
      const y = sectionRef.current.getBoundingClientRect().top + window.pageYOffset - yOffset;
      
      window.scrollTo({top: y, behavior: 'smooth'});
      setActiveSection(sectionId);
    }
  };


  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };


  const formatTranscript = (transcript) => {
    const lines = transcript.split('\n');
    
    return lines.slice(1).map((line, index) => {
      // Check for specific keywords and apply special formatting
      if (line.includes("USER_LONG_SILENCE_DETECTED")) {
        return (
          <div key={index} className="transcript-special">
            <span style={{ fontSize: '0.8em', textAlign: 'center', width: '100%' }}>
              User didn't speak for a while
            </span>
          </div>
        );
      } else if (line.includes("ECHO_LONG_SILENCE_DETECTED")) {
        return (
          <div key={index} className="transcript-special">
            <span style={{ fontSize: '0.8em', textAlign: 'center', width: '100%' }}>
              Echo got interrupted
            </span>
          </div>
        );
      }
  
      // Normal transcript formatting
      const match = line.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} - (User|Echo): (.*)$/);
      if (match) {
        const [, speaker, text] = match;
        return (
          <div key={index} className={`transcript-line ${speaker.toLowerCase()}`}>
            <div className="speaker">{speaker}</div>
            <div className="text">{text}</div>
          </div>
        );
      }
      return null;
    }).filter(Boolean);
  };
  


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const suffix = day === 1 || day === 21 || day === 31 ? 'st' : day === 2 || day === 22 ? 'nd' : day === 3 || day === 23 ? 'rd' : 'th';
    return `${day}${suffix} ${month}, ${hour}:${minute} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
  };


  const handleBack = () => {
    navigate(-1); // 
  };

  const toggleMode = () => {
    setMode(prevMode => prevMode === 'test' ? 'live' : 'test');
    setLoading(true); 
  };

  useEffect(() => {
    if (questionAnalysisData) {
      const initialSelectedThemes = {};
      Object.keys(questionAnalysisData).forEach(questionKey => {
        const themes = questionAnalysisData[questionKey]?.themes || [];
        if (themes.length > 0) {
          initialSelectedThemes[questionKey] = themes[0].theme;
        }
      });
      setSelectedThemes(initialSelectedThemes);

      // Show arrows for initially selected themes
      setTimeout(() => {
        Object.entries(initialSelectedThemes).forEach(([questionKey, theme]) => {
          const selectedBar = document.querySelector(`[data-question="${questionKey}"][data-theme="${theme}"] .theme-bar`);
          if (selectedBar) {
            const arrow = selectedBar.querySelector('.theme-bar-arrow');
            if (arrow) {
              arrow.style.display = 'block';
            }
          }
        });
      }, 0);
    }
  }, [questionAnalysisData]);

  const generateExcel = () => {
    if (!reportData || !questionAnalysisData) {
      console.error('Report data or question analysis data is missing');
      return;
    }

    const workbook = XLSX.utils.book_new();

    const reportSummaryData = [
      ['Report Title', reportData.report_summary?.report_title || ''],
      ['Research Summary', reportData.report_summary?.research_summary || ''],
      [],
      ['Key Findings'],
      ['Finding Title', 'Percentage', 'Description', 'Representative Quotes'],
      ...(reportData.report_summary?.key_findings?.map(finding => [
        finding.finding_title || '',
        finding.percentage || '',
        finding.finding_description || '',
        (finding.representative_quotes || []).join('; ')
      ]) || []),
      [],
      ['Actionable Insights'],
      ...(reportData.report_summary?.actionable_insights?.map(insight => [insight]) || [])
    ];

    const reportSummarySheet = XLSX.utils.aoa_to_sheet(reportSummaryData);
    XLSX.utils.book_append_sheet(workbook, reportSummarySheet, 'Report Summary');

    const questionAnalysisSheetData = [
      ['Question', 'Theme', 'Percentage', 'Summary', 'Representative Quotes']
    ];

    Object.entries(questionAnalysisData).forEach(([questionKey, questionData]) => {
      if (questionData && Array.isArray(questionData.themes)) {
        questionData.themes.forEach(theme => {
          questionAnalysisSheetData.push([
            questionData.question_text || '',
            theme.theme || '',
            theme.percentage || '',
            theme.summary || '',
            (theme.representative_quotes || []).join('; ')
          ]);
        });
      }
    });

    const questionAnalysisSheet = XLSX.utils.aoa_to_sheet(questionAnalysisSheetData);
    XLSX.utils.book_append_sheet(workbook, questionAnalysisSheet, 'Question Analysis');

    XLSX.writeFile(workbook, `${reportData.report_summary?.report_title || 'Campaign Report'}.xlsx`);
  };

  const handleThemeClick = (questionKey, theme) => {
    setSelectedThemes(prevThemes => ({
      ...prevThemes,
      [questionKey]: theme
    }));
  
    // Hide all arrows
    document.querySelectorAll('.theme-bar-arrow').forEach(arrow => {
      arrow.style.display = 'none';
    });
  
    // Show arrow for selected bar
    const selectedBar = document.querySelector(`[data-question="${questionKey}"][data-theme="${theme}"] .theme-bar`);
    if (selectedBar) {
      const arrow = selectedBar.querySelector('.theme-bar-arrow');
      if (arrow) {
        arrow.style.display = 'block';
      }
    }
  };

  // if (loading) {
  //   return (
  //     <div className="loading-indicator-container">
  //       <div className="loading-indicator"></div>
  //     </div>
  //   );
  // }

  // if (!reportData || Object.keys(reportData).length === 0) {
  //   return <div className="no-data-message">No respondent has completed an interview yet, please check back again after some time.</div>;
  // }

  return (
    <div className={`campaign-report-container ${isDrawerOpen ? 'drawer-open' : ''}`}>
      <div className={`navigation-drawer ${isDrawerOpen ? 'open' : ''}`}>
        <div className="drawer-header">
            <h3>Sections</h3>
            <button onClick={toggleDrawer} className="drawer-toggle">
              <FontAwesomeIcon icon={faBars} />
            </button>
        </div>
        <nav>
        <ul>
            {[
              { id: 'research-summary', label: 'Research Summary' },
              { id: 'key-findings', label: 'Key Findings' },
              { id: 'recommendations', label: 'Recommendations' },
              { id: 'question-analysis', label: 'Question Analysis' },
              { id: 'transcripts', label: 'Transcripts' }
            ].map((section) => (
              <li
                key={section.id}
                className={activeSection === section.id ? 'active' : ''}
                onClick={() => scrollToSection(section.id)}
              >
                {section.label}
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="campaign-report-content">
      <div className="campaign-report-header">
      <button onClick={handleBack} className="back-button-report">
          <FontAwesomeIcon icon={faArrowLeft} /> 
        </button>
      <div className='header-title'>{reportData?.campaign_name || 'Campaign'} Report </div>
      <div className="header-actions">

        <button onClick={generateExcel} className="download-button" disabled={loading || error}>
          <FontAwesomeIcon icon={faDownload} />
        </button>

        <div className="mode-toggle" data-mode={mode} onClick={toggleMode}>
            <div className="mode-slider"></div>
            <div className="mode-text test">Test </div>
            <div className="mode-text live">Live </div>
        </div>

      </div>
        
      </div>
      {loading ? (
        <div className="loading-indicator-container">
          <div className="loading-indicator"></div>
        </div>
      ) 
      : error ? (
        <div className="no-data-message">{error}</div>
      )
       : ((!reportData || Object.keys(reportData).length === 0) && !loading) ? (
        <div className="no-data-message">No respondent has completed an interview yet, please check back again after some time.</div>
      ) : (
      <div className="report-body">
        <h1>{reportData.report_summary?.report_title || 'Report Title'}</h1>
        <h2> Number of respondents: {respondentCount}</h2>

        <section ref={sectionRefs.current['research-summary']} className="research-summary">
          <h2><FontAwesomeIcon icon={faFileAlt} className="icon research-summary-icon" />  Research Summary</h2>
          <p>{reportData.report_summary?.research_summary || 'Research Summary not available.'}</p>
        </section>

        <section ref={sectionRefs.current['key-findings']} className="key-findings">
          <h2><FontAwesomeIcon icon={faLightbulb} className="icon key-findings-icon" /> Key Findings</h2>
          {reportData.report_summary?.key_findings?.map((finding, index) => (
            <div key={index} className="key-finding">
              <details open={index === 0}>
                <summary>
                  <div className="finding-header">
                    <span className="theme">{finding.finding_title || 'No theme available'}</span>
                    <span className="summary">{finding.finding_description || 'No summary available'}</span>
                  </div>
                </summary>
                <div className="quote-container">
                  {finding.representative_quotes?.map((quote, i) => (
                    <blockquote key={i} className="quote">
                      {`"${quote}"`}
                    </blockquote>
                  ))}
                </div>
              </details>
            </div>
          ))}
        </section>

        <section ref={sectionRefs.current['recommendations']} className="recommendations">

          <h2><FontAwesomeIcon icon={faCheckCircle} className="icon recommendations-icon" />Recommendations</h2>
          <ul>
            {reportData.report_summary?.actionable_insights?.map((insight, index) => (
              <li key={index}>{insight}</li>
            ))}
          </ul>
        </section>

        <section ref={sectionRefs.current['question-analysis']} className="question-analysis">
          <h2><FontAwesomeIcon icon={faLightbulb} className="icon question-analysis-icon" /> Question Level Analysis</h2>
          {questionAnalysisData && Object.keys(questionAnalysisData).map((questionKey, index) => {
            const questionData = questionAnalysisData[questionKey];
            const selectedTheme = selectedThemes[questionKey];
            const selectedThemeObject = questionData?.themes?.find(t => t.theme === selectedTheme);
            
            if (!questionData || !questionData.themes) {
              return null;
            }
            return (
              <div key={index} className="question-analysis-item">
                <h3>{questionAnalysisData[questionKey].question_text}</h3>
                <hr />
                <div className="question-analysis-content">
                  <div className="question-analysis-themes">
                    {questionAnalysisData[questionKey].themes
                      .sort((a, b) => b.percentage - a.percentage)
                      .map((theme, themeIndex) => (
                        <div key={themeIndex} className="theme-bar-wrapper">
                          <div className="theme-text-container">
                            <span className="theme-text">{theme.theme}</span>
                          </div>
                          <div
                            className="theme-bar-container"
                            data-question={questionKey}
                            data-theme={theme.theme}
                            onClick={() => handleThemeClick(questionKey, theme.theme)}
                          >
                            <div
                              className="theme-bar"
                              style={{ 
                                width: `${theme.percentage}%`,
                                backgroundColor: adjustColorBrightness(theme.percentage, 0.5, 1.5)  
                              }}
                            >
                              {theme.percentage}%
                              <div className="theme-bar-arrow"></div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="question-analysis-details">
                    {selectedThemeObject && (
                      <>
                        <h4>{selectedThemeObject.theme}</h4>
                        <p>{selectedThemeObject.summary}</p>
                        <div className="quote-container">
                          {selectedThemeObject.representative_quotes.map((quote, i) => (
                            <blockquote key={i} className="quote">
                              {`"${quote}"`}
                            </blockquote>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </section>
        <section ref={sectionRefs.current['transcripts']} className="transcripts">
              <h2><FontAwesomeIcon icon={faFileAlt} className="icon transcripts-icon" /> Transcripts</h2>
              
              <section className="transcripts-flex-container">
                
              <section className="transcripts-table-container">
                <table className="transcripts-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      {transcripts.length > 0 &&
                        transcripts[0].identification_fields?.fields?.map((field, index) =>
                          field && field !== 'none' ? <th key={index}>{field}</th> : null 
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {transcripts.map((transcript, rowIndex) => (
                      <tr
                        key={transcript.id}
                        className={`transcript-item ${
                          selectedTranscript && selectedTranscript.id === transcript.id
                            ? 'selected'
                            : ''
                        }`}
                        onClick={() => handleTranscriptClick(transcript)}
                      >
                        <td>{formatDate(transcript.created_at)}</td>
                        {transcript.identification_fields?.fields?.map((field, cellIndex) =>
                          field && field !== 'none' ? (
                            <td key={cellIndex}>
                              {transcript.identification_values[field]
                                ? transcript.identification_values[field].trim()
                                : 'N/A'}
                            </td>
                          ) : null // Render cell only if field is not null or 'none'
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>


                {/* The Transcript Content (right side) */}
                <section className="transcript-content">
                  {selectedTranscript ? (
                    <div className="transcript-dialogue">
                      {formatTranscript(selectedTranscript.written_transcript)}
                    </div>
                  ) : (
                    <p>Select a transcript to view its content</p>
                  )}
                </section>
              </section>

              {/* Pagination Controls */}
              {totalPages > 1 && (
                <section className="pagination">
                  <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  <span>Page {currentPage} of {totalPages}</span>
                  <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </section>
              )}
            </section>




        </div>
        )}
      </div>
    </div>
  );
};

const adjustColorBrightness = (percentage, minFactor = 0.5, maxFactor = 1.5) => {
  const baseColor = { r: 129, g: 90, b: 240 }; // RGB for #815AF0
  const white = { r: 255, g: 255, b: 255 }; // RGB for #FFFFFF
  
  const factor = minFactor + (maxFactor - minFactor) * (percentage / 100);

  const adjustedColor = {
    r: Math.round(baseColor.r + (white.r - baseColor.r) * (1 - factor)),
    g: Math.round(baseColor.g + (white.g - baseColor.g) * (1 - factor)),
    b: Math.round(baseColor.b + (white.b - baseColor.b) * (1 - factor)),
  };

  return `rgb(${adjustedColor.r}, ${adjustedColor.g}, ${adjustedColor.b})`;
};

export default CampaignReport;
