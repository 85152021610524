import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import CampaignBuilder from './CampaignBuilder';
import UserMenu from './UserMenu';
import logo from '../assets/echovane_logo.png';
import CircularProgress from '@mui/material/CircularProgress';
import { FaCopy, FaEllipsisV } from 'react-icons/fa';
import mixpanel from 'mixpanel-browser';
import ConfirmDialog from './ConfirmDialog';

mixpanel.init('1702428ef9acdb5aa84df81256d5fe13', { debug: false, track_pageview: false, persistence: 'localStorage' });



const AppContent = () => {
  
  useEffect(() => {
    mixpanel.track('DashboardHomePageViewed');
  }, []);

  const [campaigns, setCampaigns] = useState([]);
  const [showBuilder, setShowBuilder] = useState(false);
  const [backendAuthComplete, setBackendAuthComplete] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total_count, setTotalCount] = useState(0);
  const pageSize = 10; 
  const campaignBuilderRef = useRef();
  const [copiedMessage, setCopiedMessage] = useState('');
  const [menuOpen, setMenuOpen] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const menuRef = useRef(null);
  const [deleteError, setDeleteError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const campaignsFetched = useRef(false);
  

  const { loginWithRedirect, logout, user, isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    const handleClickOutside = (event) => {
      
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) && 
        !event.target.closest('.menu-button') 
      ) {
        setMenuOpen(null);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  useEffect(() => {
    const checkAndRedirect = async () => {
      if (!isLoading && !isAuthenticated) {
        try {
          await loginWithRedirect({
            appState: { returnTo: window.location.pathname }
          });
        } catch (error) {
          console.error('Login error:', error);
          setError('Failed to log in. Please try again.');
        }
      }
    };

    checkAndRedirect();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    const fetchTokens = async () => {
      if (isAuthenticated && user) {
        try {
          const accessToken = await getAccessTokenSilently();
          const idTokenClaims = await getIdTokenClaims();
        
          setAccessToken(accessToken);
          setIdToken(idTokenClaims.__raw);
        } catch (error) {
          console.error('Error fetching tokens:', error);
          setError('Failed to fetch authentication tokens. Please try logging in again.');
        }
      }
    };

    fetchTokens();
  }, [isAuthenticated, user]);

  const handleCampaignReportClick = (campaign) => {
    if (accessToken && idToken) {
      navigate(`/report/${campaign.id}`, { 
        state: { 
          accessToken, 
          idToken,
          testLink: campaign.test_link,
          liveLink: campaign.live_link
        } 
      });
    } else {
      console.error('Tokens are not available');
    }
  };
  
  const handleViewParticipants = (campaign) => {
    navigate('/participants', { state: { campaignId: campaign.id } });
  };


  const showErrorMessage = (message) => {
    setError(message);
    setTimeout(() => {
      setError(null);
    }, 3000); // Hide error message after 3 seconds
  };
  

  const toggleMenu = (campaignId) => {
    setMenuOpen(menuOpen === campaignId ? null : campaignId);
  };

  const handleDeleteClick = (campaign) => {
    setCampaignToDelete(campaign);
    setShowDeleteConfirm(true);
    setMenuOpen(null);
  };

  const handleDeleteConfirm = async () => {
    if (campaignToDelete) {
      try {
        const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignToDelete.id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'X-ID-Token': idToken,
          },
        });
  
        if (response.ok) {
          setCampaigns(campaigns.filter(c => c.id !== campaignToDelete.id));
          mixpanel.track('CampaignDeleted', { campaignId: campaignToDelete.id });
          setMenuOpen(null); 
          setDeleteError(null); 
          setShowDeleteConfirm(false);
        } else {
          const errorData = await response.json();
          showErrorMessage(errorData.message || 'Failed to delete campaign');
        }
      } catch (error) {
        console.error('Error deleting campaign:', error);
        showErrorMessage('An error occurred while deleting the campaign');
      }
    }
    setCampaignToDelete(null);
  };


  useEffect(() => {
    if (campaignsFetched.current) {
      return;
    }

    const fetchUserDataandCampaigns = async () => {
      if (isAuthenticated && user) {
        try {
          const accessToken = await getAccessTokenSilently();
          const idToken = await getIdTokenClaims();

          console.log("fetching user");
    
          const response = await fetch('https://dashboard-api.echovane.com/api/auth/user', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'X-ID-Token': idToken.__raw,
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
              email: user.email,
            })
          });
          
          
          const responseData = await response.json();
          

          if (response.status === 200) {
            setBackendAuthComplete(true);
            console.log("fetching campaigns");
            fetchCampaigns(currentPage);
            campaignsFetched.current = true;
          } else {
            console.error('Backend authentication failed:', responseData);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };
  
    fetchUserDataandCampaigns();
  }, [isAuthenticated, user]);

  
  const handleCopyLink = (link, type) => {
    navigator.clipboard.writeText(link);
    setCopiedMessage(`${type} link copied`);
    
    setTimeout(() => {
      setCopiedMessage('');
    }, 3000); // Hide message after 3 seconds
  };


  const fetchCampaigns = async (page = 1) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      
      const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns?page=${page}&page_size=${pageSize}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'X-ID-Token': idToken.__raw
        }
      });
      if (response.ok) {
        const data = await response.json();
        
        setCampaigns(data.campaigns);
        console.log(data.campaigns);
        setTotalCount(data.total_count);
        console.log("total_count", data.total_count);
        setTotalPages(Math.ceil(data.total_count / pageSize));
        setCurrentPage(page);
      } else {
        console.error('Failed to fetch campaigns');
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      fetchCampaigns(currentPage - 1);
    }
  };
  
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      fetchCampaigns(currentPage + 1);
    }
  };
  

  const handleCampaignClick = (campaign) => {
    navigate(`/research/${campaign.id}`);
  };
  


  const handleOutsideClick = async (event) => {
    if (event.target.className.includes('overlay')) {
      if (campaignBuilderRef.current) {
        await campaignBuilderRef.current.checkAndGenerateQuestions();
      }
      setShowBuilder(false);
    }
  };


  if (isLoading || (isAuthenticated && !backendAuthComplete)) {
    return <div className="loading-indicator-container">
              <div className="loading-indicator"></div>
            </div>;
  }

  if (!isAuthenticated) {
    return null;
  }

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  const handleCreateNewResearch = () => {
    navigate('/research');
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <>
      {loading ? null : (
        <div className="container">
          <header className="header">
            <img src={logo} alt="Echovane Logo" className="logo" />
            <div className="user-menu">
              {isAuthenticated && (
                <UserMenu
                  userInitial={user?.name ? user.name.charAt(0) : 'U'}
                  onLogout={handleLogout}
                />
              )}
            </div>
          </header>
          <main className="content">
            {campaigns.length === 0 ? (
              <div className="empty-state">
                <button className="create-button" onClick={handleCreateNewResearch}>
                  Create Research Project
                </button>
                <div className="description">
                  Start your journey to impactful insights with your first research project.
                </div>
              </div>
            ) : (
              <>
                <div className="campaign-list-container" style={{ position: 'relative' }}>
                  <div className="create-button-container">
                    <button className="create-button" onClick={handleCreateNewResearch}>
                      Create New Research
                    </button>
                  </div>
                  <div className={`campaign-list ${loading ? 'loading' : ''}`}>
                    <table>
                      <thead>
                        <tr>
                          <th>Research</th>
                          <th>Status</th>
                          <th>Last Updated</th>
                          <th>Test Link</th>
                          <th>Live Link</th>
                          <th>Report</th>
                          <th>Recruit</th> 
                        </tr>
                      </thead>
                      <tbody>
                        {campaigns.map((campaign) => (
                          <tr key={campaign.id}>
                            <td>
                              <a
                                href="#"
                                className="campaign-name-link"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleCampaignClick(campaign);
                                }}
                              >
                                {campaign.name || 'Untitled Research'}
                              </a>
                            </td>
                            <td>{campaign.status}</td>
                            <td>{campaign.updated_at}</td>
                            <td>
                                {campaign.test_link ? (
                                  <button
                                    className="copy-button"
                                    onClick={() => {
                                      let link = `https://feedback.echovane.com/interviews/${campaign.test_link}`;

                                      // Check if identification method is 'link' and append fields with 'xxxx'
                                      if (campaign.identification_method === 'link' && campaign.identification_fields?.fields) {
                                        const queryParams = campaign.identification_fields.fields
                                          .map(field => `${field}=xxxx`)
                                          .join('&');
                                        link += `?${queryParams}`;
                                      }

                                      handleCopyLink(link, 'Test');
                                    }}
                                    title="Copy Test Interview Link"
                                  >
                                    <FaCopy />
                                  </button>
                                ) : (
                                  '--'
                                )}
                              </td>


                              <td>
                                  {campaign.live_link ? (
                                    <button
                                      className="copy-button"
                                      onClick={() => {
                                        let link = `https://feedback.echovane.com/interviews/${campaign.live_link}`;

                                        if (campaign.identification_method === 'link' && campaign.identification_fields?.fields) {
                                          const queryParams = campaign.identification_fields.fields
                                            .map(field => `${field}=xxxx`)
                                            .join('&');
                                          link += `?${queryParams}`;
                                        }

                                        handleCopyLink(link, 'Live');
                                      }}
                                      title="Copy Live Interview Link"
                                    >
                                      <FaCopy />
                                    </button>
                                  ) : (
                                    '--'
                                  )}
                                </td>


                            <td>
                              <a
                                href="#"
                                className="report-link"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleCampaignReportClick(campaign);
                                }}
                              >
                                View Report
                              </a>
                            </td>

                            <td>
                              <div className="action-menu">
                                <button onClick={() => toggleMenu(campaign.id)} className="menu-button">
                                  <FaEllipsisV />
                                </button>
                                {menuOpen === campaign.id && (
                                  <div ref={menuRef} className="menu-dropdown">
                                    <button onClick={() => handleDeleteClick(campaign)} className="delete-option">Delete</button>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              {campaign.audience_type === 'own' ? (
                                '--'
                              ) : (
                                <button
                                  className="view-button"
                                  onClick={() => handleViewParticipants(campaign)}
                                >
                                  View
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
            {total_count > pageSize && (
              <div className="pagination-controls">
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className="pagination-button"
                >
                  Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className="pagination-button"
                >
                  Next
                </button>
              </div>
            )}
          </main>
          {copiedMessage && (
              <div className="copied-message">
                {copiedMessage}
              </div>
            )}

        </div>
        
      )}
        <ConfirmDialog
          isOpen={showDeleteConfirm}
          onClose={() => {
            setShowDeleteConfirm(false);
            setDeleteError(null);
            setCampaignToDelete(null);
          }}
          onConfirm={handleDeleteConfirm}
          title="Are you sure you want to delete this campaign?"
          description="This action cannot be undone. This will permanently delete the campaign and all associated interviews and insight reports, if any."
        />
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}
    </>
  );
  
}

export default AppContent;