import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import './CampaignBuilder.css';
import { FaSave, FaEdit, FaTrashAlt, FaArrowLeft } from 'react-icons/fa'; 
import DeleteIcon from '@mui/icons-material/Delete';
import consumerResearchImg from '../assets/consumer_research.svg';
import adTestingImg from '../assets/ad_test.svg';
import conceptTestingImg from '../assets/concept_testing.svg';
import consumerFeedbackImg from '../assets/consumer_feedback.svg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useAuth0 } from '@auth0/auth0-react';
import { ThreeDots } from 'react-loader-spinner';
import mixpanel, { identify } from 'mixpanel-browser';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Select from 'react-select';
import { FaGripVertical } from 'react-icons/fa';

mixpanel.init('1702428ef9acdb5aa84df81256d5fe13', { debug: false, track_pageview: false, persistence: 'localStorage' });


const CampaignBuilder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [campaign, setCampaign] = useState(null);
  const [step, setStep] = useState(campaign ? campaign.current_step : 0);

  const [selectedBlock, setSelectedBlock] = useState(null); 

  const [questions, setQuestions] = useState(campaign && Array.isArray(campaign.questions) ? campaign.questions.map((q, index) => ({
    id: `question-${index + 1}`,
    question: q.question,
    followup: q.followup
  })) : []);
  
  const handleBackClick = () => {
    navigate(-1); 
  };
  
  const [selectedFile, setSelectedFile] = useState(null);
  const [editIndex, setEditIndex] = useState(null); 
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false); 
  const [newQuestion, setNewQuestion] = useState('');
  const [audienceOption, setAudienceOption] = useState('own'); 
  const [isGeneratingQuestions, setIsGeneratingQuestions] = useState(false);

  const [researchObjective, setResearchObjective] = useState(campaign ? campaign.business_objective : '');
  const [conceptDescription, setConceptDescription] = useState(campaign ? campaign.context : '');
  const [businessUsage, setBusinessUsage] = useState(campaign ? campaign.business_usage : '');
  const questionsGeneratedRef = useRef(false);


  const countryOptions = [{ value: 'United States', label: 'United States' }, { value: 'Canada', label: 'Canada' }];
  const stateOptions = [{ value: 'All', label: 'All' }, { value: 'California', label: 'California' }];
  const educationOptions = [{ value: 'bachelordegree', label: 'Bachelor Degree' }, { value: 'masterdegree', label: 'Masters Degree' }];
  const occupationOptions = [{ value: 'Full-time', label: 'Full-time' }, { value: 'Part-time', label: 'Part-time' }];
  const incomeRangeOptions = [{ value: '30,000-39,999', label: '30,000-39,999' }, { value: '60,000-79,999', label: '60,000-79,999' }];

  const [campaignName, setCampaignName] = useState(campaign ? campaign.name : '');
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [initialObjective, setInitialObjective] = useState('');
  const [initialQuestions, setInitialQuestions] = useState(campaign ? campaign.questions : []);
  const [testLink, setTestLink] = useState(campaign ? campaign.test_link : null);
  const [liveLink, setLiveLink] = useState(campaign ? campaign.live_link : null);
  const [initialContext, setInitialContext] = useState('');
  const [navigatedBack, setNavigatedBack] = useState(false);
  const researchObjectiveRef = useRef(null);
  const conceptDescriptionRef = useRef(null);
  const [identificationMethod, setIdentificationMethod] = useState('none');
  const [customFieldInputVisible, setCustomFieldInputVisible] = useState(false); 
  const [fullLoading, setFullLoading] = useState(false); 
  
  const [suggestedFieldsForLink, setSuggestedFieldsForLink] = useState(["name", "email", "user_id"]); 
  const [suggestedFieldsForAsk, setSuggestedFieldsForAsk] = useState(["Name", "Email", "User ID"]); 

  const [selectedFieldsForLink, setSelectedFieldsForLink] = useState([]); 
  const [selectedFieldsForAsk, setSelectedFieldsForAsk] = useState([]); 
  
  const [minAge, setMinAge] = useState('');
  const [maxAge, setMaxAge] = useState('');
  const criteriaOptions = ["Country", "Age", "Household Income", "Gender", "Language", "Education Level"];
  const [availableCriteria, setAvailableCriteria] = useState(criteriaOptions); 
  const [savedRecruitmentCriteria, setSavedRecruitmentCriteria] = useState(null);
  const [savedScreenerQuestions, setSavedScreenerQuestions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]); 
  const [selectedState, setSelectedState] = useState([]); 
  const [selectedEducation, setSelectedEducation] = useState([]); 
  const [selectedOccupation, setSelectedOccupation] = useState([]); 
  const [selectedIncomeRange, setSelectedIncomeRange] = useState([]); 
  
  const [selectedGender, setSelectedGender] = useState(null); 
  const [selectedLanguage, setSelectedLanguage] = useState([]); 
  const [screenerQuestions, setScreenerQuestions] = useState([]); 
  const [numberOfParticipants, setNumberOfParticipants] = useState(0);
  const [incentiveAmount, setIncentiveAmount] = useState(0.0);
  
  const [customField, setCustomField] = useState(""); 
  const { campaignId: urlCampaignId } = useParams(); 
  const [campaignId, setCampaignId] = useState(urlCampaignId || null); 
  
  const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' },
  ];
  
  const languageOptions = [
    { value: 'English', label: 'English' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'French', label: 'French' },

  ];
  const [targetAudience, setTargetAudience] = useState(null);
  const [isCustomField, setIsCustomField] = useState({});
  const [showRecruitmentSheet, setShowRecruitmentSheet] = useState(false);
  const [showScreenerSheet, setShowScreenerSheet] = useState(false);
  const [selectedCriteria, setSelectedCriteria] = useState([]);


  const steps = audienceOption === 'own' 
  ? ['Research Type', 'Objective', 'Concept', 'Questions', 'Who', 'Review', 'Test', 'Identify'] 
  : ['Research Type', 'Objective', 'Concept', 'Questions', 'Who', 'Review', 'Test'];

  const multipleChoiceActionOptions = [
    { value: 'may-select', label: 'May select' },
    { value: 'must-select', label: 'Must select' },
    { value: 'disqualify', label: 'Disqualify' }
  ];
  
  const singleChoiceActionOptions = [
    { value: 'qualify', label: 'Qualify' },
    { value: 'disqualify', label: 'Disqualify' }
  ];
  
  
  const questionTypeOptions = [
    { value: 'multiple', label: 'Multiple Choice' },
    { value: 'single', label: 'Single Choice' },
    { value: 'short', label: 'Short Answer' },
    { value: 'long', label: 'Long Answer' }
  ];
  
  
const addNewQuestion = (questionType) => {
  setScreenerQuestions([
    ...screenerQuestions,
    {
      id: `question-${screenerQuestions.length + 1}`,
      questionText: '',
      questionType: questionType,
      options: ['short', 'long'].includes(questionType) ? [] : [{ text: '', action: 'none' }],
      required: false,
      autoAccept: [],
      autoReject: [],
    },
  ]);
};

const handleAddOption = (questionId, optionIndex) => {
  setScreenerQuestions(prevQuestions =>
    prevQuestions.map(q =>
      q.id === questionId
        ? {
            ...q,
            options: [
              ...q.options.slice(0, optionIndex + 1),
              { text: '', action: 'none' },
              ...q.options.slice(optionIndex + 1),
            ],
          }
        : q
    )
  );
};

  
const handleRemoveOption = (questionId, optionIndex) => {
  setScreenerQuestions(prevQuestions =>
    prevQuestions.map(q => {
      if (q.id === questionId) {
        const updatedOptions = [...q.options];
        updatedOptions.splice(optionIndex, 1); // Remove the selected option

        return { ...q, options: updatedOptions };
      }
      return q;
    })
  );
};


  const handleToggleRequired = (questionId) => {
    const updatedQuestions = screenerQuestions.map((q) =>
      q.id === questionId ? { ...q, required: !q.required } : q
    );
    setScreenerQuestions(updatedQuestions);
  };

  const handleAutoAcceptReject = (questionId, optionIndex, type) => {
    const updatedQuestions = screenerQuestions.map((q) => {
      if (q.id === questionId) {
        const updatedList =
          type === 'accept'
            ? { autoAccept: [...q.autoAccept, optionIndex], autoReject: q.autoReject.filter(i => i !== optionIndex) }
            : { autoReject: [...q.autoReject, optionIndex], autoAccept: q.autoAccept.filter(i => i !== optionIndex) };
        return { ...q, ...updatedList };
      }
      return q;
    });
    setScreenerQuestions(updatedQuestions);
  };
  
  useEffect(() => {
    if (showScreenerSheet && screenerQuestions.length === 0) {
      addNewQuestion('multiple');
    }
  }, [showScreenerSheet]);
  

  useEffect(() => {
    
    const fetchCampaignData = async () => {
      if (campaignId) {
        setFullLoading(true); 
         
        try {
          
          const accessToken = await getAccessTokenSilently();
          const idTokenClaims = await getIdTokenClaims();
          const idToken = idTokenClaims.__raw;

          const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'X-ID-Token': idToken,
              'Content-Type': 'application/json',
            },
          });

          if (response.ok) {
            const fetchedCampaign = await response.json();
            console.log("campaign ", fetchedCampaign);
            setCampaign(fetchedCampaign);
          } else {
            console.error('Failed to fetch campaign data');
          }
        } catch (error) {
          console.error('Error fetching campaign data:', error);
        } finally {
          setFullLoading(false); 
        }
      }
      else {
        setStep(0);
        setCampaignName('');
        setSelectedBlock(null);
        setQuestions([]);
        setIdentificationMethod('no_identify'); 
        setSelectedFieldsForLink([]); 
        setSelectedFieldsForAsk([]); 
    }
    };

    fetchCampaignData();
  }, []);


  useEffect(() => {
    mixpanel.track('CampaignBuilderViewed');
  }, []);

  useEffect(() => {
    if (campaign) {
      if (step === 1 && researchObjectiveRef.current) {
        setInitialObjective(researchObjectiveRef.current.value || '');
      }
      if (step === 2 && conceptDescriptionRef.current) {
        setInitialContext(conceptDescriptionRef.current.value || '');
      }
    }
  }, [campaign, step]);

  const applyRecruitmentSettings = (campaign) => {
    if (campaign.audience_option === "recruit") {
      setAudienceOption("recruit");
  
      setNumberOfParticipants(campaign.target_audience.number_of_participants || 0);
      setIncentiveAmount(campaign.target_audience.incentive_amount || 0.0);
  
      setSelectedCountry(
        (campaign.target_audience.countries || []).map(countryValue => {
          return countryOptions.find(option => option.value === countryValue) || { value: countryValue, label: countryValue };
        })
      );
  
      setSelectedState(
        (campaign.target_audience.state || []).map(stateValue => {
          return stateOptions.find(option => option.value === stateValue) || { value: stateValue, label: stateValue };
        })
      );
  
      setSelectedEducation(
        (campaign.target_audience.education || []).map(educationValue => {
          return educationOptions.find(option => option.value === educationValue) || { value: educationValue, label: educationValue };
        })
      );
  
      setSelectedOccupation(
        (campaign.target_audience.occupation || []).map(occupationValue => {
          return occupationOptions.find(option => option.value === occupationValue) || { value: occupationValue, label: occupationValue };
        })
      );
  
      setSelectedIncomeRange(
        (campaign.target_audience.income_range || []).map(incomeValue => {
          return incomeRangeOptions.find(option => option.value === incomeValue) || { value: incomeValue, label: incomeValue };
        })
      );
  
      setMinAge(campaign.target_audience.age_min || '');
      setMaxAge(campaign.target_audience.age_max || '');
  
      setSelectedGender(
        campaign.target_audience.gender
          ? genderOptions.find(option => option.value === campaign.target_audience.gender) || { value: campaign.target_audience.gender, label: campaign.target_audience.gender }
          : null
      );
  
      setSelectedLanguage(
        (campaign.target_audience.languages || []).map(languageValue => {
          return languageOptions.find(option => option.value === languageValue) || { value: languageValue, label: languageValue };
        })
      );
  
      const recruitmentCriteria = {
        countries: (campaign.target_audience.countries || []).map(countryValue => {
          return countryOptions.find(option => option.value === countryValue)?.label || countryValue;
        }),
        state: (campaign.target_audience.state || []).map(stateValue => {
          return stateOptions.find(option => option.value === stateValue)?.label || stateValue;
        }),
        age: { min: campaign.target_audience.age_min, max: campaign.target_audience.age_max },
        education: (campaign.target_audience.education || []).map(educationValue => {
          return educationOptions.find(option => option.value === educationValue)?.label || educationValue;
        }),
        occupation: (campaign.target_audience.occupation || []).map(occupationValue => {
          return occupationOptions.find(option => option.value === occupationValue)?.label || occupationValue;
        }),
        incomeRange: (campaign.target_audience.income_range || []).map(incomeValue => {
          return incomeRangeOptions.find(option => option.value === incomeValue)?.label || incomeValue;
        }),
        gender: campaign.target_audience.gender
          ? genderOptions.find(option => option.value === campaign.target_audience.gender)?.label || campaign.target_audience.gender
          : null,
        language: (campaign.target_audience.languages || []).map(languageValue => {
          return languageOptions.find(option => option.value === languageValue)?.label || languageValue;
        }),
      };
  
      setSavedRecruitmentCriteria(recruitmentCriteria);
  
      let criteriaList = [];
  
      if ((campaign.target_audience.countries || []).length > 0) criteriaList.push('Country');
      if ((campaign.target_audience.state || []).length > 0) criteriaList.push('State');
      if (campaign.target_audience.age_min || campaign.target_audience.age_max) criteriaList.push('Age');
      if ((campaign.target_audience.education || []).length > 0) criteriaList.push('Education Level');
      if ((campaign.target_audience.occupation || []).length > 0) criteriaList.push('Occupation');
      if ((campaign.target_audience.income_range || []).length > 0) criteriaList.push('Household Income');
      if (campaign.target_audience.gender) criteriaList.push('Gender');
      if ((campaign.target_audience.languages || []).length > 0) criteriaList.push('Language');
  
      setSelectedCriteria(criteriaList);
      setAvailableCriteria(criteriaOptions.filter(option => !criteriaList.includes(option)));
  
      if (campaign.target_audience.screener_questions) {
        const screenerQuestionsData = campaign.target_audience.screener_questions.map((q, index) => ({
          id: `question-${index + 1}`,
          questionText: q.question_text,
          questionType: q.question_type,
          options: q.options || [],
          required: q.required || false,
        }));
        setScreenerQuestions(screenerQuestionsData);
        setSavedScreenerQuestions(screenerQuestionsData);
      }
    }
  };

  
  useEffect(() => {
    if (step === 3 && !navigatedBack) {
      checkAndGenerateQuestions();
    }
  }, [step, navigatedBack]);

  useEffect(() => {
    if (campaign) {
      setStep(campaign.current_step || 0);
      setCampaignName(campaign.name || '');
      console.log("campaign audeince before ", campaign.audience_option);
      setAudienceOption(campaign.audience_option);
      setTargetAudience(campaign.target_audience);
      setResearchObjective(campaign.business_objective || '');
      setConceptDescription(campaign.context || '');
      setInitialObjective(campaign.business_objective || '');
      setInitialContext(campaign.context || '');
      
      const typeToBlockMap = {
        'consumer_research': 1,
        'ad_testing': 2,
        'concept_testing': 3,
        'consumer_feedback': 4
      };
      setSelectedBlock(typeToBlockMap[campaign.type] || null);
      
      if (campaign?.questions) {
        setQuestions(campaign.questions.map((q, index) => ({
          id: `question-${index + 1}`,
          question: q.question,
          followup: q.followup
        })));
      }

      applyRecruitmentSettings(campaign);

      if (campaign.identification_method) {
        setIdentificationMethod(campaign.identification_method);
  
        if (campaign.identification_method === 'link') {
          setSelectedFieldsForLink(campaign.identification_fields?.fields || []);
        } else if (campaign.identification_method === 'ask') {
          setSelectedFieldsForAsk(campaign.identification_fields?.fields || []);
        }
      }

    } else {
      setStep(0);
      setCampaignName('');
      setSelectedBlock(null);
      setQuestions([]);
      setIdentificationMethod('no_identify'); 
      setSelectedFieldsForLink([]); 
      setSelectedFieldsForAsk([]); 
  }
  }, [campaign]);

  const hasCampaignChanged = () => {
    const currentObjective = researchObjective;
    const currentContext = conceptDescription;
    const currentQuestions = questions;

    const objectiveChanged = currentObjective !== initialObjective;
    const contextChanged = currentContext !== initialContext;
    const questionsChanged = JSON.stringify(currentQuestions) !== JSON.stringify(initialQuestions);

    return objectiveChanged || contextChanged || questionsChanged;
};

const handleCriteriaSelect = (option) => {
  if (!selectedCriteria.includes(option)) {
    setAvailableCriteria(availableCriteria.filter((item) => item !== option));
    setSelectedCriteria([...selectedCriteria, option]);
  }
};

const handleAddField = (field, isFromSuggested = false, optionType = 'link') => {
  
    setIsCustomField({
      ...isCustomField,
      [field]: !isFromSuggested, 
    });

    if (isFromSuggested) {
      if (optionType === 'link') {
        setSelectedFieldsForLink([...selectedFieldsForLink, field]);
        setSuggestedFieldsForLink(suggestedFieldsForLink.filter(suggestedField => suggestedField !== field));
      } else if (optionType === 'info') {
        setSelectedFieldsForAsk([...selectedFieldsForAsk, field]);
        setSuggestedFieldsForAsk(suggestedFieldsForAsk.filter(suggestedField => suggestedField !== field));
      }
    }
  
};


const handleRemoveField = (field, optionType = 'link') => { 

  if (!isCustomField[field]) {

    if (optionType === 'link') {
      setSelectedFieldsForLink(selectedFieldsForLink.filter(selectedField => selectedField !== field));

      setSuggestedFieldsForLink([...suggestedFieldsForLink, field]);
    } else if (optionType === 'info') {

      setSelectedFieldsForAsk(selectedFieldsForAsk.filter(selectedField => selectedField !== field));
      setSuggestedFieldsForAsk([...suggestedFieldsForAsk, field]);
    }
  }
  else {
    if (optionType === 'link') {
      setSelectedFieldsForLink(selectedFieldsForLink.filter(selectedField => selectedField !== field));
    } else if (optionType === 'info') {
      setSelectedFieldsForAsk(selectedFieldsForAsk.filter(selectedField => selectedField !== field));
    }

  }

  const updatedCustomFieldStatus = { ...isCustomField };
  delete updatedCustomFieldStatus[field];
  setIsCustomField(updatedCustomFieldStatus);
};


const handleCriteriaDeselect = (option) => {

  setSelectedCriteria(selectedCriteria.filter((item) => item !== option));

  setAvailableCriteria([...availableCriteria, option]);

  switch (option) {
    case 'Country':
      setSelectedCountry([]); 
      break;
    case 'State':
      setSelectedState([]); 
      break;
    case 'Age':
      setMinAge(''); 
      setMaxAge(''); 
      break;
    case 'Education Level':
      setSelectedEducation([]); 
      break;
    case 'Occupation':
      setSelectedOccupation([]); 
      break;
    case 'Household Income':
      setSelectedIncomeRange([]); 
      break;
    case 'Gender':
      setSelectedGender(null); 
      break;
    case 'Language':
      setSelectedLanguage([]); 
      break;
    default:
      break;
  }
};



const handleAddCustomField = (optionType) => {
  if (customField.trim()) {
    let fieldToAdd = customField.trim();

    if (optionType === "link") {
      fieldToAdd = fieldToAdd.toLowerCase().replace(/\s+/g, '_');
    }

    if (optionType === "link") {
      if (!selectedFieldsForLink.includes(fieldToAdd)) {
        setSelectedFieldsForLink([...selectedFieldsForLink, fieldToAdd]);
      }
    } else {
      if (!selectedFieldsForAsk.includes(fieldToAdd)) {
        setSelectedFieldsForAsk([...selectedFieldsForAsk, fieldToAdd]);
      }
    }

    setIsCustomField({
      ...isCustomField,
      [fieldToAdd]: true, 
    });

    setCustomField(""); 
  }
};

  const generateCampaignQuestions = async (instruction) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;
  
      const payload = instruction ? { instruction } : {};
  
      const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}/generate-questions`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to generate questions: ${errorText}`);
      }
  
      const data = await response.json();
  
      return data.questions;
    } catch (error) {
      console.error('Error generating questions:', error);
      throw error;
    }
  };
  

  const handleFileDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };


  const continueStep = async () => {
    console.log("step ", step);
    console.log("step.length", steps.length);

    setLoading(true); 
    setNavigatedBack(false);
    
    await saveCurrentStep();
    
    if (step === 5) {
      try {
          const campaignChanged = hasCampaignChanged();
          
          const accessToken = await getAccessTokenSilently();
          const idTokenClaims = await getIdTokenClaims();
          const idToken = idTokenClaims.__raw;
  
          if (testLink && campaignChanged) {
              
              const response = await fetch(`https://dashboard-api.echovane.com/api/interviews/${testLink.id}`, {
                  method: 'PUT',
                  headers: {
                      'Authorization': `Bearer ${accessToken}`,
                      'X-ID-Token': idToken,
                      'Content-Type': 'application/json'
                  }
                  
              });
  
              if (!response.ok) {
                  throw new Error('Failed to update the interview');
              }
  
              const updatedInterview = await response.json();
              setTestLink(updatedInterview);
          } else if (!testLink) {
              
              const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}/interviews`, {
                  method: 'POST',
                  headers: {
                      'Authorization': `Bearer ${accessToken}`,
                      'X-ID-Token': idToken,
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                      interview_type: "test"  
                  })
              });
  
              if (!response.ok) {
                  throw new Error('Failed to create the interview');
              }
  
              const newInterview = await response.json();
              
              setTestLink(newInterview.id);
          }
      } catch (error) {
          console.error('Error during interview API call:', error);
      }
  }
  
  if (step === 6) {

    try {
        const campaignChanged = hasCampaignChanged();

        const accessToken = await getAccessTokenSilently();
        const idTokenClaims = await getIdTokenClaims();
        const idToken = idTokenClaims.__raw;

        if (liveLink && campaignChanged) {
            
            const response = await fetch(`https://dashboard-api.echovane.com/api/interviews/${liveLink.id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'X-ID-Token': idToken,
                    'Content-Type': 'application/json'
                }
                
            });

            if (!response.ok) {
                throw new Error('Failed to update the live interview');
            }

            const updatedLiveInterview = await response.json();
            setLiveLink(updatedLiveInterview);  
        } else if (!liveLink) {
            
            const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}/interviews`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'X-ID-Token': idToken,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    interview_type: "live" 
                })
            });

            if (!response.ok) {
                throw new Error("Failed to create the live interview :", response);
            }

            const newLiveInterview = await response.json();
            
            setLiveLink(newLiveInterview.id);
        }
    } catch (error) {
        console.error('Error during live interview API call:', error);
    }
}

if (step < steps.length) {
  let nextStep = step + 1;

  if (nextStep === 2 && (selectedBlock === 1 || selectedBlock === 4)) {
      nextStep = 3;
  }


      if (nextStep !== 3) {
        questionsGeneratedRef.current = false;
      }
  

  setStep(nextStep);
}

if (step == steps.length - 1) {
  console.log("called before back");
  handleBackClick();
}

    setLoading(false); 
};

  const createOrUpdateCampaign = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;

      const url = campaignId && campaignId != 'null'
      ? `https://dashboard-api.echovane.com/api/campaigns/${campaignId}`
      : 'https://dashboard-api.echovane.com/api/campaigns';

      const method = campaignId ? 'PUT' : 'POST';


      const campaignData = {
        name: campaignName || 'Untitled Campaign',
        type: selectedBlock ? ['consumer_research', 'ad_testing', 'concept_testing', 'consumer_feedback'][selectedBlock - 1] : null,
        business_objective: document.getElementById('researchObjective')?.value || '',
        context: document.getElementById('conceptDescription')?.value || '',
        status: 'Draft',
        current_step: step
      };
  

      const response = await fetch(url, {
        method: method,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(campaignData)
      });
  

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to create/update campaign: ${errorText}`);
      }

      const data = await response.json();
      
      return data;
    } catch (error) {
      console.error('Error creating/updating campaign:', error);
      throw error;
    }
  };

  const handleQuestionTextChange = (questionId, newText) => {
    setScreenerQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId ? { ...q, questionText: newText } : q
      )
    );
  };


  const saveCurrentStep = async () => {
    let campaignData = {};

    switch (step) {
        case 0:
            const researchType = selectedBlock 
                ? ['consumer_research', 'ad_testing', 'concept_testing', 'consumer_feedback'][selectedBlock - 1] 
                : null;

            campaignData = {
                name: campaignName,
                type: researchType,
                current_step: step
            };

            if (campaignId) {
                await updateCampaign(campaignData);
            } else {
                campaignData = await createOrUpdateCampaign();
                // setCampaign(campaignData);
                setCampaignId(campaignData.id);
            }
            break;

        case 1:
            campaignData = {
                business_objective: document.getElementById('researchObjective').value,
                name: campaignName,
                current_step: step
            };

            await updateCampaign(campaignData);
            break;

        case 2:
            campaignData = {
                context: document.getElementById('conceptDescription').value,
                name: campaignName,
                current_step: step
            };

            await updateCampaign(campaignData);
            break;

        case 3:
            campaignData = {
              questions: questions.map(q => ({
                  question: q.question,
                  followup: q.followup
              })),
              name: campaignName,
              current_step: step
          };

          await updateCampaign(campaignData);
          break;

        
        case 4:
          
          const targetData = audienceOption === 'recruit' ? {
            countries: selectedCountry.map(c => c.value),
            state: selectedState.map(s => s.value),
            education: selectedEducation.map(e => e.value),
            occupation: selectedOccupation.map(o => o.value),
            income_range: selectedIncomeRange.map(i => i.value),
            age_min: minAge || null,
            age_max: maxAge || null,
            gender: selectedGender ? selectedGender.value : null,
            languages: selectedLanguage.map(l => l.value),
            number_of_participants: numberOfParticipants,  
            incentive_amount: incentiveAmount,  
            screener_questions: screenerQuestions.map(question => ({
              question_text: question.questionText,
              question_type: question.questionType,
              required: question.required,
              options: question.questionType === 'multiple' || question.questionType === 'single'
                ? question.options.map(option => ({
                    text: option.text,
                    action: option.action
                  }))
                : ['short', 'long'].includes(question.questionType)
                  ? [{ text: question.answerText || '' }]  
                  : []
            })),
            
          } : null;
    
          const hasRecruitmentDataChanged = JSON.stringify(targetAudience) !== JSON.stringify(targetData);
    
          if (hasRecruitmentDataChanged) {
            campaignData = {
              audience_option: audienceOption,
              target_audience: targetData,
              name: campaignName,
              current_step: step,
            };
            await updateCampaign(campaignData);
          }
          break;

        case 6:
          campaignData = {
            name: campaignName,
            audience_option: audienceOption,
            status: "Live",
            current_step: step
        };

          await updateCampaign(campaignData);
          break;

        case 7:
          let identificationFields = {};
    
          if (identificationMethod === 'link') {
            identificationFields = { fields: selectedFieldsForLink };
          } else if (identificationMethod === 'ask') {
            identificationFields = { fields: selectedFieldsForAsk };
          }
    
          campaignData = {
            identification_method: identificationMethod,
            identification_fields: identificationFields,
            current_step: step,
            name: campaignName,
          };
    
          await updateCampaign(campaignData);
          break;


        default:
            campaignData = {
                name: campaignName,
                current_step: step
            };

            await updateCampaign(campaignData);
            break;
    }
};

const checkAndGenerateQuestions = () => {
  const objectiveChanged = researchObjective !== initialObjective;
  const contextChanged = conceptDescription !== initialContext;


  if (objectiveChanged || contextChanged) {
    setIsGeneratingQuestions(true);
    
    // Return a promise that resolves immediately
    return new Promise((resolve) => {
      // Use setTimeout to move the operation to the next event loop tick
      setTimeout(async () => {
        try {
          const generatedQuestions = await generateCampaignQuestions();
          const newQuestions = generatedQuestions.map((q, index) => ({
            id: `question-${index + 1}`,
            question: q.question,
            followup: q.followup
          }));
          
          // Update questions in the state
          setQuestions(newQuestions);

          // Update campaign data
          await updateCampaign({
            questions: newQuestions,
            business_objective: researchObjective,
            context: conceptDescription
          });

          // Update initial values
          setInitialObjective(researchObjective);
          setInitialContext(conceptDescription);
        } catch (error) {
          console.error("Error generating questions:", error);
        } finally {
          setIsGeneratingQuestions(false);
        }
      }, 0);

      // Resolve the promise immediately
      resolve();
    });
  } else {
    // If no changes, return a resolved promise
    return Promise.resolve();
  }
};


const updateCampaign = async (campaignData) => {
    try {
        console.log("update data ", campaignData);
        const accessToken = await getAccessTokenSilently();
        const idTokenClaims = await getIdTokenClaims();
        const idToken = idTokenClaims.__raw;


        const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'X-ID-Token': idToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(campaignData)
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response:', errorText);
            throw new Error(`Failed to update campaign: ${response.status} ${response.statusText}`);
        }

        const updatedCampaign = await response.json();
        return updatedCampaign;
    } catch (error) {
        console.error('Error updating campaign:', error);
        throw error;
    }
};

const handleSaveRecruitmentCriteria = () => {
  // Collect the selected recruitment criteria
  const recruitmentCriteria = {
    countries: selectedCountry.map(c => c.label),
    state: selectedState.map(s => s.label),
    age: { min: minAge, max: maxAge },
    education: selectedEducation.map(e => e.label),
    occupation: selectedOccupation.map(o => o.label),
    incomeRange: selectedIncomeRange.map(i => i.label),
    gender: selectedGender?.label,
    language: selectedLanguage?.map(l => l.label),
  };

  // Save the recruitment criteria and close the sheet
  setSavedRecruitmentCriteria(recruitmentCriteria);
  setShowRecruitmentSheet(false);
};

const handleSaveScreenerQuestions = () => {
  // Collect the selected screener questions
  const screenerQuestionList = [...screenerQuestions]; // Assuming you have screener questions logic elsewhere
  setSavedScreenerQuestions(screenerQuestionList);
  setShowScreenerSheet(false);
};

const handleEditRecruitmentCriteria = () => {
  setShowRecruitmentSheet(true);
};

// Function to edit screener questions (re-open the screener sheet)
const handleEditScreenerQuestions = () => {
  setShowScreenerSheet(true);
};

const handleQuestionChange = (index, field, value) => {
  const updatedQuestions = questions.map((q, i) =>
    i === index ? { ...q, [field]: value } : q
  );
  setQuestions(updatedQuestions);
};

    const handleOnDragEnd = (result) => {
      
      if (!result.destination) {
        
        return;
      }

      const items = Array.from(questions);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      
      setQuestions([...items]);
    };
  
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };


const customFilterOption = (option, rawInput) => {
  const words = rawInput.split(' ');
  const fullString = option.label.toLowerCase();
  return words.reduce((acc, cur) => {
    return acc && fullString.includes(cur.toLowerCase());
  }, true);
};


const handleAddQuestion = () => {
  if (newQuestion.trim() !== '') {
    const newQuestionObj = {
      id: `question-${questions.length + 1}`,
      question: newQuestion,
      followup: ''
    };
    setQuestions([...questions, newQuestionObj]);
    setNewQuestion('');
  }
};

const handleDeleteQuestion = (index) => {
  const updatedQuestions = questions.filter((_, i) => i !== index);
  setQuestions(updatedQuestions);
};

const handleEditQuestion = (index) => {
  setEditIndex(index);
};

const handleQuestionTypeChange = (questionId, newQuestionType) => {
  setScreenerQuestions(prevQuestions => 
    prevQuestions.map(q => {
      if (q.id === questionId) {
        const minOptions = newQuestionType === 'multiple' ? 2 : 2;
        let updatedOptions = q.options;

        // Ensure there are at least minOptions for the selected question type
        if (updatedOptions.length < minOptions) {
          const optionsToAdd = minOptions - updatedOptions.length;
          for (let i = 0; i < optionsToAdd; i++) {
            updatedOptions.push({ text: '', action: '' });
          }
        }

        return { ...q, questionType: newQuestionType, options: updatedOptions };
      }
      return q;
    })
  );
};




const handleResearchObjectiveChange = (e) => setResearchObjective(e.target.value);
const handleConceptDescriptionChange = (e) => setConceptDescription(e.target.value);

const handleStepChange = (newStep, navigatedBack) => {

  if (navigatedBack) {
    

    if ( step === 3) {
      
      if (selectedBlock === 1 || selectedBlock === 4) {
        setStep(1); 
      } else {
        setStep(newStep);
      }
    }
  else {
      setStep(newStep);
    }
  }
  else {
    setStep(newStep);
  }
};

const handleSave = () => {
  saveCurrentStep();
  checkAndGenerateQuestions();
};

const hasNonEmptyCriteria = (criteria) => {
  if (!criteria) return false;
  const { country, state, age, education, occupation, incomeRange, gender, language } = criteria;
  return (
    (country && country.length > 0) ||
    (state && state.length > 0) ||
    ((age && (age.min || age.max))) ||
    (education && education.length > 0) ||
    (occupation && occupation.length > 0) ||
    (incomeRange && incomeRange.length > 0) ||
    (gender && gender !== '') ||
    (language && language.length > 0)
  );
};



  if (fullLoading) {
    return (
      <div className="loading-indicator-container">
        <div className="loading-indicator">
        </div>
      </div>
    );
  }

  const handleOptionChange = (questionId, optionIndex, newText) => {
    setScreenerQuestions(prevQuestions =>
      prevQuestions.map(q =>
        q.id === questionId
          ? {
              ...q,
              options: q.options.map((option, idx) =>
                idx === optionIndex ? { ...option, text: newText } : option
              ),
            }
          : q
      )
    );
  };

  const handleAnswerTextChange = (questionId, newText) => {
    setScreenerQuestions(prevQuestions =>
      prevQuestions.map(q =>
        q.id === questionId
          ? { ...q, answerText: newText }
          : q
      )
    );
  };
  

  const handleOptionActionChange = (questionId, optionIndex, newAction) => {
    setScreenerQuestions(prevQuestions =>
      prevQuestions.map(q =>
        q.id === questionId
          ? {
              ...q,
              options: q.options.map((option, idx) =>
                idx === optionIndex ? { ...option, action: newAction } : option
              ),
            }
          : q
      )
    );
  };

  const handleScreenerDragEnd = (result) => {
    if (!result.destination) return;
  
    const items = Array.from(screenerQuestions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    setScreenerQuestions(items);
  };
  
  const handleDeleteScreenerQuestion = (questionId) => {
    setScreenerQuestions((prevQuestions) =>
      prevQuestions.filter((q) => q.id !== questionId)
    );
  };
  
  

  return (
    <div className="campaign-builder-container">
      <button className="back-button-campaign-builder" onClick={handleBackClick}>
        <FaArrowLeft /> 
      </button>
                  {/* Bottom Sheet for Recruitment Criteria */}
                  <div className={`bottom-sheet-recruit ${showRecruitmentSheet ? 'show' : ''}`}>
                  {showRecruitmentSheet && (
                    <>
                    <div className = "bottom-sheet-header">
                      {/* <h3>Add Recruitment Criteria</h3> */}
                      <div className="bottom-sheet-actions">
                          <button className="back-button" onClick={() => setShowRecruitmentSheet(false)}>
                            Cancel
                          </button>
                          <button className="continue-button" onClick={handleSaveRecruitmentCriteria}>
                            Save
                          </button>
                      </div>
                      </div>
                      <hr className="screener-line" />
                      <div className="bottom-sheet-body">
                      <div className="recruitment-left">
                        <h3>Available Recruitment Criteria</h3>
                        <hr className="filter-divider" />
                        
                        {availableCriteria.length === 0 ? (
                        <p className="empty-message">All criteria have been selected.</p>
                      ) : (
                        <div className="criteria-list">
                        {availableCriteria.map((option) => (
                          <div key={option} className="criteria-chip" onClick={() => handleCriteriaSelect(option)}>
                            {option} <span>+</span>
                          </div>
                        ))}
                        </div>
                      )}
                      </div>
                      <div className="recruitment-right">
                        <h3>Selected Recruitment Criteria</h3>
                        <hr className="filter-divider" />
                        {selectedCriteria.length === 0 ? (
                          <p className="empty-message">No criteria selected yet.</p>
                        ) : (
                        <div className="filters-container">
                        {selectedCriteria.includes('Country') && (
                              <div className="filter">
                                <div className="filter-header">
                                  <h4>Country</h4>
                                  <button className="delete-button" onClick={() => handleCriteriaDeselect('Country')}><DeleteIcon /></button>
                                </div>
                                
                                <Select
                                  isMulti
                                  options={countryOptions}
                                  placeholder="Search Country"
                                  classNamePrefix="custom-select"
                                  value={selectedCountry}
                                  onChange={setSelectedCountry}
                                />
                                <hr className="filter-divider" />
                              </div>
                            )}
                          {selectedCriteria.includes('State') && (
                            <div className="filter">
                                <div className="filter-header">
                                  <h4>State</h4>
                                  <button className="delete-button" onClick={() => handleCriteriaDeselect('State')}><DeleteIcon /></button>
                                </div>
                               
                              <Select
                                isMulti
                                options={stateOptions}
                                placeholder="Search State"
                                classNamePrefix="custom-select"
                                value={selectedState}
                                onChange={setSelectedState}
                              />
                               <hr className="filter-divider" />
                            </div>
                          )}
                            {selectedCriteria.includes('Age') && (
                              <div className="filter age-filter">
                                <div className="filter-header">
                                  <h4>Age</h4>
                                  <button className="delete-button" onClick={() => handleCriteriaDeselect('Age')}><DeleteIcon /></button>
                                </div>
                                
                                <div className="age-input-group">
                                  <input
                                    type="number"
                                    placeholder="Min Age"
                                    value={minAge}
                                    onChange={(e) => setMinAge(e.target.value)}
                                    className="age-input"
                                  />
                                  <span className="age-separator">to</span>
                                  <input
                                    type="number"
                                    placeholder="Max Age"
                                    value={maxAge}
                                    onChange={(e) => setMaxAge(e.target.value)}
                                    className="age-input"
                                  />
                                </div>
                                <hr className="filter-divider" />
                              </div>
                            )}


                            {selectedCriteria.includes('Household Income') && (
                                <div className="filter">
                                <div className="filter-header">
                                  <h4>Household Income</h4>
                                  <button className="delete-button" onClick={() => handleCriteriaDeselect('Household Income')}><DeleteIcon /></button>
                                </div>
                                
                                  <Select
                                    isMulti
                                    options={incomeRangeOptions}
                                    placeholder="Search Income Range"
                                    classNamePrefix="custom-select"
                                    value={selectedIncomeRange}
                                    onChange={setSelectedIncomeRange}
                                  />
                                  <hr className="filter-divider" />
                                </div>

                              )}

                              {selectedCriteria.includes('Gender') && (
                                <div className="filter">
                                <div className="filter-header">
                                  <h4>Gender</h4>
                                  <button className="delete-button" onClick={() => handleCriteriaDeselect('Gender')}><DeleteIcon /></button>
                                </div>
                                
                                  <Select
                                    options={genderOptions}
                                    placeholder="Select Gender"
                                    classNamePrefix="custom-select"
                                    value={selectedGender}
                                    onChange={setSelectedGender}
                                  />
                                  <hr className="filter-divider" />
                                </div>
                              )}

                              {selectedCriteria.includes('Language') && (
                                <div className="filter">
                                <div className="filter-header">
                                  <h4>Language</h4>
                                  <button className="delete-button" onClick={() => handleCriteriaDeselect('Language')}><DeleteIcon /></button>
                                </div>
                               
                                  <Select
                                    isMulti
                                    options={languageOptions}
                                    placeholder="Search Language"
                                    classNamePrefix="custom-select"
                                    value={selectedLanguage}
                                    onChange={setSelectedLanguage}
                                  />
                                   <hr className="filter-divider" />
                                </div>
                              )}

                          {selectedCriteria.includes('Education Level') && (
                            <div className="filter">
                                <div className="filter-header">
                                  <h4>Education Level</h4>
                                  <button className="delete-button" onClick={() => handleCriteriaDeselect('Education Level')}><DeleteIcon /></button>
                                </div>
                                
                              <Select
                                isMulti
                                options={educationOptions}
                                placeholder="Search Education Level"
                                classNamePrefix="custom-select"
                                value={selectedEducation}
                                onChange={setSelectedEducation}
                              />
                              <hr className="filter-divider" />
                            </div>
                          )}
                          {/* Add other criteria as needed */}
                        </div>
                        )}
                      </div>
                      </div>

                    </>
                  )}
                </div>

                {/* Bottom Sheet for Screener Questions */}

                <div className={`bottom-sheet-screener ${showScreenerSheet ? 'show' : ''}`}>
                  {showScreenerSheet && (
                    <>
                        <div className="bottom-sheet-header">
                          <h3>Add Screener Questions</h3>
                          <div className="bottom-sheet-actions">
                            <button className="back-button" onClick={() => setShowScreenerSheet(false)}>Cancel</button>
                            <button className="continue-button" onClick={handleSaveScreenerQuestions}>Save</button>
                          </div>
                        </div>
                        <hr className="screener-line" />
                      <DragDropContext onDragEnd={handleScreenerDragEnd}>
                        <Droppable droppableId="screenerQuestions">
                          {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                      {screenerQuestions.map((q, index) => (
                          <Draggable key={q.id} draggableId={q.id} index={index}>
                            {(provided) => (
                        <div key={q.id} className="screener-question"        
                         ref={provided.innerRef}
                        {...provided.draggableProps}>
                                  <div className="drag-handle" {...provided.dragHandleProps}>
                                  <FaGripVertical />
                                  </div>
                          <div className="question-row">

                          <input
                            type="text"
                            value={q.questionText}
                            onChange={(e) => handleQuestionTextChange(q.id, e.target.value)}
                            placeholder={`Enter question ${index + 1}`}
                          />
                              <Select
                                value={questionTypeOptions.find(opt => opt.value === q.questionType)}
                                onChange={(selectedOption) => handleQuestionTypeChange(q.id, selectedOption.value)}
                                options={questionTypeOptions}
                                className="question-type-select"
                              />  

                            <button
                            className="delete-button-screener-question"
                            onClick={() => handleDeleteScreenerQuestion(q.id)}
                          >
                            <FaTrashAlt />
                          </button>
                          </div>
                          {q.questionType === 'single' || q.questionType === 'multiple' ? (
                            <div className="options-container">
{[
  ...q.options,
  ...Array(Math.max(0, (q.questionType === 'multiple' ? 2 : 2) - q.options.length)).fill({ text: '', action: '' })
].map((option, optionIndex) => (
  <div key={optionIndex} className="option-input">
    <input
      type={q.questionType === 'multiple' ? 'checkbox' : 'radio'}
      disabled
    />
    <input
      type="text"
      value={option.text}
      onChange={(e) => handleOptionChange(q.id, optionIndex, e.target.value)}
      placeholder={`Option ${optionIndex + 1}`}
    />
    <Select
      value={q.questionType === 'multiple' 
        ? multipleChoiceActionOptions.find(opt => opt.value === option.action) 
        : singleChoiceActionOptions.find(opt => opt.value === option.action)
      }
      onChange={(selectedOption) => handleOptionActionChange(q.id, optionIndex, selectedOption.value)}
      options={q.questionType === 'multiple' ? multipleChoiceActionOptions : singleChoiceActionOptions}
      className="option-action-select"
    />
    <button
      className="add-option-button"
      onClick={() => handleAddOption(q.id, optionIndex)}
    >
      +
    </button>
    {optionIndex >= 2 && (
      <button
        className="remove-option-button"
        onClick={() => handleRemoveOption(q.id, optionIndex)}
        disabled={q.questionType === 'multiple' ? q.options.length <= 1 : q.options.length <= 1} 
      >
        -
      </button>
    )}
  </div>
))}


                                </div>

                          ) : q.questionType === 'short' ? (
                            <input
                              type="text"
                              placeholder="Short Answer"
                              value={q.answerText || ''} 
                              onChange={(e) => handleAnswerTextChange(q.id, e.target.value)} // Track changes
                              className="short-answer-placeholder"
                            />
                          )  : (
                            <textarea
                              placeholder="Long Answer"
                              value={q.answerText || ''} 
                              onChange={(e) => handleAnswerTextChange(q.id, e.target.value)} // Track changes
                              className="long-answer-placeholder"
                            />
                          )
                          
                          }
                          <label className="required-label">
                            <input
                              type="checkbox"
                              checked={q.required}
                              onChange={() => handleToggleRequired(q.id)}
                            />
                            Required
                          </label>
                        </div>
                            )}
                            </Draggable>
                          
                        
                      ))}
                      {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                      
                      <button className="add-question-button-screener" onClick={() => addNewQuestion('multiple')}>
                        Add Question
                      </button>


                      
                      </>
                    )}
                  </div>


    <div className="builder-container" onClick={(e) => e.stopPropagation()}>
      <div className="progress-container">
      <div className="progress-indicator">
            {steps.filter((_, index) => 
              !(selectedBlock === 1 || selectedBlock === 4) || index !== 2
            ).map((stepName, index) => {
              // Adjust the step number for comparison
              const adjustedStep = (selectedBlock === 1 || selectedBlock === 4) && index > 1 ? index + 1 : index;
              const visibleStep = (selectedBlock === 1 || selectedBlock === 4) ? 
                [0, 1, 3, 4, 5, 6, 7][index] : 
                index;
              
              return (
                <span
                  key={index}
                  className={`step ${visibleStep === step ? 'current-step' : ''} ${visibleStep <= step ? 'completed' : 'incomplete'}`}
                  onClick={() => handleStepChange(visibleStep, false)}
                >
                  {stepName}
                  {index < (steps.length - (selectedBlock === 1 || selectedBlock === 4 ? 2 : 1)) && (
                    <span className={`separator ${visibleStep <= step ? 'completed' : 'incomplete'}`}> &gt; </span>
                  )}
                </span>
              );
            })}
          </div>


          <div className="button-container">
      {step > 0 && (
              <button className="back-button" onClick={() => handleStepChange((step - 1), true)}>
        <FaArrowLeft /> Back
      </button>

      )}
      <button className="save-button" onClick={handleSave}>
          <FaSave /> Save Campaign
      </button>
          <button 
      className="continue-button" 
      onClick={continueStep} 
      disabled={loading}  
    >
        {loading ? (
          <ThreeDots color="#fff" height={10} width={24} />
        ) : (
          step === steps.length - 1 ? "Submit" : "Continue"
        )}
    </button>

  </div>
</div>
<div className="builder-header">
      <input
              type="text"
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
              placeholder="Add a name for your research campaign"
              className="campaign-name-input"
          />
        <div className="builder-content">
          {step === 0 && (
            <div className="research-type">
              <h1>Choose the type of research you want to do</h1>
              <div className="research-grid">
                <div className="research-options">
                  {[{
                    id: 1, title: 'Consumer Research', description: 'Understand preferences and behaviors of your target consumers', img: consumerResearchImg
                  }

                //   , {
                //     id: 2, title: 'Ad Testing', description: 'Evaluate the effectiveness of your advertisements', img: adTestingImg
                //   }
                // ].map((block) => (
                //     <div
                //       className={`research-block ${block.id === selectedBlock ? 'selected' : ''}`}
                //       key={block.id}
                //       onClick={() => setSelectedBlock(block.id)}
                //     >
                //       <img src={block.img} alt={block.title} className="research-image" />
                //       <div className="block-text">
                //         <h2>{block.title}</h2>
                //         <p>{block.description}</p>
                //       </div>
                //     </div>
                //   ))}
                // </div>
                // <div className="research-options">
                //   {[{
                //     id: 3, title: 'Concept Testing', description: 'Test new product ideas and features', img: conceptTestingImg
                //   }
                  
                  , {
                    id: 4, title: 'Consumer Feedback', description: 'Gather feedback on your products or services directly from your customers', img: consumerFeedbackImg
                  }].map((block) => (
                    <div
                      className={`research-block ${block.id === selectedBlock ? 'selected' : ''}`}
                      key={block.id}
                      onClick={() => setSelectedBlock(block.id)}
                    >
                      <img src={block.img} alt={block.title} className="research-image" />
                      <div className="block-text">
                        <h2>{block.title}</h2>
                        <p>{block.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {step === 1 && (
            <div className="objective-step">
              <div className="question-block">
                <label htmlFor="researchObjective">
                  What’s your research objective?
                </label>
                <p className="example-text">
                  Example: "To evaluate the effectiveness and user acceptance of a new AI-powered personal finance app among millennials (ages 25-40) in urban areas, with the goal of improving financial literacy and savings habits. This research aims to understand users' current financial management practices, assess the app's key features such as automated budgeting and investment recommendations, identify potential barriers to adoption, and gauge willingness to pay for premium features. The insights gathered will inform product development, marketing strategies, and potential partnerships with financial institutions, ultimately guiding the app's launch and growth strategy in the competitive fintech market."
                </p>
                <textarea
                id="researchObjective"
                value={researchObjective}
                onChange={handleResearchObjectiveChange}
              />
              </div>
              {/* <div className="question-block">
                <label htmlFor="businessUsage">
                  How will you use this research in your business?
                </label>
                <p className="example-text">
                  Example: "We will use the insights from this research to refine our product development strategy, ensure our new tea blend meets customer preferences, and create targeted marketing campaigns to maximize its launch success."
                </p>
                <textarea
                id="businessUsage"
                value={businessUsage}
                onChange={handleBusinessUsageChange}
              />
              </div> */}
            </div>
          )}
          {step === 2 && (
            <div className="concept-step">
              <div className="concept-block">
                <div className="left-part">
                  <label htmlFor="conceptDescription">
                    Write the concept as you would want to explain it to a respondent
                  </label>
                  <textarea
                id="conceptDescription"
                value={conceptDescription}
                onChange={handleConceptDescriptionChange}
              />
                </div>
                <div className="right-part">
                  <p className="example-text">
                    Example: We're excited to present our latest creation – an all-natural, organic tea blend crafted from the finest ingredients. This blend combines soothing chamomile, refreshing peppermint, and a hint of lemon verbena to create a calming and revitalizing experience. Our goal is to provide a tea that not only tastes great but also offers health benefits such as improved digestion and stress relief.
                  </p>
                </div>
              </div>
              <div className="collateral-upload">
                <label htmlFor="conceptDescription">Add any supporting collaterals </label>
                <div
                  className="upload-section"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={handleFileDrop}
                  onClick={handleBrowseClick}
                >
                  <div className="upload-icon">📁</div>
                  <div className="upload-text">
                    Drag and drop or browse to choose a file
                  </div>
                  <input
                    type="file"
                    accept="image/*,video/*"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="questions-step">
              {isGeneratingQuestions ? (
                <div className="loader-container">
                  <ThreeDots color="#6419F7" height={50} width={50} />  
                  <p>Generating questions...</p>
                </div>
              ) : (
                <div className="left-column">
                  <h2>What questions do you want to ask the respondent after showing the concept?</h2>
                  <p className="description">
                    We have generated unbiased and non-leading research standard questions for your concept. Feel free to add and edit as needed. We will still adjust the language if your questions do not meet research standards.
                  </p>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="questions">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {questions && questions.length > 0 && questions.map((question, index) => (
                            <Draggable key={question.id} draggableId={question.id} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="question-box"
                                >
                                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <div style={{ flex: 1 }}>
                                      {editIndex === index ? (
                                        <>
                                          <textarea
                                            value={question.question}
                                            onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
                                            onBlur={() => setEditIndex(null)}
                                            autoFocus
                                          />
                                          <textarea
                                            value={question.followup}
                                            onChange={(e) => handleQuestionChange(index, 'followup', e.target.value)}
                                            onBlur={() => setEditIndex(null)}
                                            placeholder="Follow-up instruction (optional)"
                                            className="followup-input"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <span>{question.question}</span>
                                          {question.followup && (
                                            <em className="followup-text">
                                              Potential Followup: {question.followup}
                                            </em>
                                          )}
                                        </>
                                      )}
                                    </div>
                                    {selectedBlock !== 1 && ( 
                                      <div className="icons">
                                        <FaEdit className="edit_icon" onClick={() => handleEditQuestion(index)} />
                                        <FaTrashAlt className="del_icon" onClick={() => handleDeleteQuestion(index)} />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  {selectedBlock !== 1 && (
                      <>
                        <textarea
                          className="new-question-input"
                          value={newQuestion}
                          onChange={(e) => setNewQuestion(e.target.value)}
                          placeholder="Type your question here"
                        />
                        <div className="add-question-container">
                          <button className="add-question-button" onClick={handleAddQuestion}>
                            Add Question
                          </button>
                        </div>
                      </>
                    )}

                </div>
              )}
            </div>
          )}

{step === 4 && (
  <div className="who-step">
    <h1>Whom do you want to target?</h1>
    <div className="audience-options">
      <label>
        <input
          type="radio"
          value="own"
          checked={audienceOption === 'own'}
          onChange={() => setAudienceOption('own')}
        />
        Target your own audience
      </label>
      <label>
        <input
          type="radio"
          value="recruit"
          checked={audienceOption === 'recruit'}
          onChange={() => setAudienceOption('recruit')}
        />
        Recruit audience <span className="beta-tag">Beta</span>
      </label>
    </div>
    <hr className="full-width-line" />

    
    {audienceOption === 'recruit' && (
      <div className="recruit-container">
            {/* Number of Participants Section */}
            <div className="input-section">
              <h2>Number of Participants</h2>
              <input
                type="number"
                className="input-box"
                placeholder="Enter number of participants"
                value={numberOfParticipants}
                onChange={(e) => setNumberOfParticipants(e.target.value)}
              />
            </div>
    
    {/* Incentive Amount Section */}
            <div className="input-section">
              <h2>Incentive Amount ($)</h2>
              <input
                type="number"
                className="input-box"
                placeholder="Enter incentive amount"
                value={incentiveAmount}
                onChange={(e) => setIncentiveAmount(e.target.value)}
              />
            </div>
            <hr className="full-width-line" />
        <div className="recruit-left-section">
          <h2>Recruitment Criteria</h2>
          <p>
            {savedRecruitmentCriteria && hasNonEmptyCriteria(savedRecruitmentCriteria)
              ? 'You have selected the following criteria:'
              : 'Set the criteria for your audience, such as age, country, income, etc.'}
          </p>

          {savedRecruitmentCriteria ? (
            <div className="saved-criteria">
              {savedRecruitmentCriteria.countries.length > 0 && (
                <p><strong>Country:</strong> {savedRecruitmentCriteria.countries.join(', ')}</p>
              )}
              {savedRecruitmentCriteria.state.length > 0 && (
                <p><strong>State:</strong> {savedRecruitmentCriteria.state.join(', ')}</p>
              )}
              {savedRecruitmentCriteria.age.min && savedRecruitmentCriteria.age.max && (
                <p><strong>Age:</strong> {savedRecruitmentCriteria.age.min} - {savedRecruitmentCriteria.age.max}</p>
              )}
              {savedRecruitmentCriteria.education.length > 0 && (
                <p><strong>Education:</strong> {savedRecruitmentCriteria.education.join(', ')}</p>
              )}
              {savedRecruitmentCriteria.occupation.length > 0 && (
                <p><strong>Occupation:</strong> {savedRecruitmentCriteria.occupation.join(', ')}</p>
              )}
              {savedRecruitmentCriteria.incomeRange.length > 0 && (
                <p><strong>Income Range:</strong> {savedRecruitmentCriteria.incomeRange.join(', ')}</p>
              )}
              {savedRecruitmentCriteria.gender && (
                <p><strong>Gender:</strong> {savedRecruitmentCriteria.gender}</p>
              )}
              {savedRecruitmentCriteria.language.length > 0 && (
                <p><strong>Language:</strong> {savedRecruitmentCriteria.language.join(', ')}</p>
              )}
              <button className="edit-button" onClick={handleEditRecruitmentCriteria}>Edit</button>
            </div>
          ) : (
          <button className="add-button" onClick={() => setShowRecruitmentSheet(true)}>Add</button>
        )}
        </div>
        <hr className="full-width-line" />
        <div className="recruit-right-section">
          <h2>Screener Questions</h2>
          <p>Ask questions to further filter your audience.</p>
          {savedScreenerQuestions.length > 0 ? (
            <div className="saved-screener-questions">
              <ul>
                {savedScreenerQuestions.map((q, index) => (
                  <li key={index}>{q.questionText}</li> // Updated line
                ))}
              </ul>
              <button className="edit-button" onClick={handleEditScreenerQuestions}>Edit</button>
            </div>
          ) : (
            <button className="add-button" onClick={() => setShowScreenerSheet(true)}>Add</button>
          )}
        </div>

      </div>
    )}

              </div>
            )}


        {step === 5 && (
          <div className="review-step">
            <h1>Review Your Research Design</h1>
            <div className="review-section">
              <h2>Research Type</h2>
              <div className="review-content">
                {selectedBlock === 1 && 'Consumer Research'}
                {selectedBlock === 2 && 'Ad Testing'}
                {selectedBlock === 3 && 'Concept Testing'}
                {selectedBlock === 4 && 'Consumer Feedback'}
              </div>
            </div>
            <div className="review-section">
              <h2>Research Objective</h2>
              <div className="review-content">{researchObjective}</div>
            </div>
            {selectedBlock !== 1 && selectedBlock !== 4 && (
            <div className="review-section">
              <h2>Concept Description</h2>
              <div className="review-content">{conceptDescription}</div>
            </div>)}
            {/* <div className="review-section">
              <h2>Business Usage</h2>
              <div className="review-content">{businessUsage}</div>
            </div> */}
            <div className="review-section">
              <h2>Questions</h2>
              <div className="review-content">
                {questions.map((q, index) => (
                  <div key={index}>
                    <p>{q.question}</p>
                    {q.followup && (
                      <em className="followup-text">
                        Potential Followup: {q.followup}
                      </em>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="review-section">
              <h2>Target Audience</h2>
              <div className="review-content">
              {audienceOption === 'recruit' ? (
                <>
                  <p>Country: {selectedCountry.map(c => c.label).join(', ')}</p>
                  <p>State: {selectedState.map(s => s.label).join(', ')}</p>
                  <p>Education: {selectedEducation.map(e => e.label).join(', ')}</p>
                  <p>Occupation: {selectedOccupation.map(o => o.label).join(', ')}</p>
                  <p>Income Range: {selectedIncomeRange.map(i => i.label).join(', ')}</p>
                </>
              ) : (
                <p>Audience: Own users</p>
              )}
            </div>

            </div>
          </div>
        )}

          {step === 6 && (
            <div className="test-step">
              <button
                className="test-interview-button"
                onClick={() => window.open(`https://feedback.echovane.com/interviews/${testLink}`, '_blank')}
              >
                Test Interview
              </button>
              <div className='test-interview-instruction'>
              Give the interview a try and make any tweaks to the questions or research objectives if you think something's missing. When you're ready, hit 'Submit' in the next step and we'll generate a Live link for you to share with your users!
              </div>
            </div>
          )}

{step === steps.length - 1 && audienceOption === 'own' && (
  <div className="identify-step">
    <h1>Identify Respondents</h1>
    <div className="identify-options">

      <label className = "identify-option-label custom-radio">
        <input
          type="radio"
          value="link"
          checked={identificationMethod === 'link'}
          onChange={() => setIdentificationMethod('link')}
        />
        <span className="custom-radio-btn"></span> 
        Add identification fields to the interview link
      </label>

      {identificationMethod === 'link' && (
        <div className="fields-section">
          <div className="selected-fields">
            {selectedFieldsForLink.length > 0 ? (
              selectedFieldsForLink.map((field, index) => (
                <span key={index} className="chip">
                  {field}
                  <span className="remove-icon" onClick={() => handleRemoveField(field, "link")}>✖</span>
                </span>
              ))
            ) : (
              <p className="empty-fields-hint">No fields added yet</p>
            )}
          </div>

          <div className="custom-field-input">
            <input
              type="text"
              value={customField}
              onChange={(e) => setCustomField(e.target.value)}
              placeholder="Enter custom field"
            />
            <button onClick={() => handleAddCustomField("link")}>Add</button>

          </div>

          <div className="suggest-label">Suggested Fields</div>
          <div className="suggested-fields">
            {suggestedFieldsForLink.map((field, index) => (
              <span key={index} className="chip" onClick={() => handleAddField(field, true, 'link')}>
                {field} <span className="add-icon">➕</span>
              </span>
            ))}
          </div>
        </div>
      )}

      <label className = "identify-option-label custom-radio">
        <input
          type="radio"
          value="ask"
          checked={identificationMethod === 'ask'}
          onChange={() => setIdentificationMethod('ask')}
        />
        <span className="custom-radio-btn"></span> 
        Ask for information from the respondent
      </label>

      {identificationMethod === 'ask' && (
        <div className="fields-section">
          {/* Selected fields section */}
          <div className="selected-fields">
            {selectedFieldsForAsk.length > 0 ? (
              selectedFieldsForAsk.map((field, index) => (
                <span key={index} className="chip">
                  {field}
                  <span className="remove-icon" onClick={() => handleRemoveField(field, "info")}>✖</span>
                </span>
              ))
            ) : (
              <p className="empty-fields-hint">No fields added yet</p>
            )}
          </div>

          <div className="custom-field-input">
            <input
              type="text"
              value={customField}
              onChange={(e) => setCustomField(e.target.value)}
              placeholder="Enter custom field"
            />
            <button onClick={() => handleAddCustomField("info")}>Add</button>
          </div>

          <div className="suggest-label">Suggested Fields</div>
          <div className="suggested-fields">
            {suggestedFieldsForAsk.map((field, index) => (
              <span key={index} className="chip" onClick={() => handleAddField(field, true, 'info')}>
                {field} <span className="add-icon">➕</span>
              </span>
            ))}
          </div>
        </div>
      )}

      <label className = "identify-option-label custom-radio">
        <input
          type="radio"
          value="none"
          checked={identificationMethod === 'none'}
          onChange={() => setIdentificationMethod('none')}
        />
        <span className="custom-radio-btn"></span> 
        No need to identify
      </label>

    </div>
  </div>
)}




        </div>
      </div>
      </div>
      </div>
  );
};

export default CampaignBuilder;
