// Participants.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import { useAuth0 } from '@auth0/auth0-react';
import './Participants.css';
import {
  FaUser,
  FaGenderless,
  FaBriefcase,
  FaBuilding,
  FaUniversity,
  FaDollarSign,
  FaMapMarkerAlt,
} from 'react-icons/fa';

const Participants = () => {
  const [participants, setParticipants] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate(); // Initialize navigate
  const { campaignId } = location.state || {};

  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (campaignId && isAuthenticated) {
      fetchParticipants();
    }
  }, [campaignId, isAuthenticated]);

  const fetchParticipants = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;

      const response = await fetch(
        `https://dashboard-api.echovane.com/api/campaigns/${campaignId}/participants`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-ID-Token': idToken,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setParticipants(data);
      } else {
        console.error('Failed to fetch participants');
      }
    } catch (error) {
      console.error('Error fetching participants:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleParticipantClick = (participant) => {
    setSelectedParticipant(participant);
  };

  const handleCloseSideSheet = () => {
    setSelectedParticipant(null);
  };

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="participants-container">
      {/* Header */}
      <div className="participants-header">
        <button onClick={handleBack} className="back-button">
          &#8592;
        </button>
        <div className="header-title">Participants</div>
      </div>

      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="participants-list">
          {participants.map((participant) => (
            <div
              key={participant.id}
              className="participant-card"
              onClick={() => handleParticipantClick(participant)}
            >
              <div className="participant-header">
                <div className="profile-pic">
                  {participant.first_name.charAt(0)}
                  {participant.last_name.charAt(0)}
                </div>
                <div className="participant-name">
                  {participant.first_name} {participant.last_name}
                </div>
              </div>
              <div className="profile-info-grid">
                <div className="info-item">
                  <FaGenderless /> {participant.gender}
                </div>
                <div className="info-item">
                  <FaUniversity /> {participant.education_level}
                </div>
                <div className="info-item">
                  <FaDollarSign /> {participant.household_income}
                </div>
                <div className="info-item">
                  <FaBriefcase /> {participant.occupation}
                </div>
                <div className="info-item">
                  <FaBuilding /> {participant.company}
                </div>
                <div className="info-item">
                  <FaMapMarkerAlt /> {participant.city}, {participant.state}, {participant.country}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {selectedParticipant && (
        <div className={`side-sheet ${selectedParticipant ? 'open' : ''}`}>
          <div className="side-sheet-header">
            <h2>Screener Responses</h2>
            <button className="close-button" onClick={handleCloseSideSheet}>
              &times;
            </button>
          </div>
          <div className="screener-responses">
            {selectedParticipant.screener_responses.map((response, index) => (
              <div key={index} className="response-item">
                <strong>{response.question}</strong>
                <p>{response.response}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Participants;
